.grid1{
    background-color: black;
}
.mainGrid{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color:#000;
}
.App-paper-2{
    background-color: black;
}
.MuiSelect-selectMenu-162 {
    width: 88px;
    height: auto;
    overflow: hidden;
    min-height: 1.1875em;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: blue;
}
h1{
    margin:0px;
    padding: 0px;
}